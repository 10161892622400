import { Container, useColorMode } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BannerVeoJadeklinik from "../../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaser from "../../components/choc/LogoHeadlineTeaser"
import QuadTeaser from "../../components/choc/QuadTeaser"
import LayoutA from "../../components/layout/LayoutA"
import ScrollHeader from "../../components/ScrollHeader"
import ThreeColumnGridBoxes from "../../components/ThreeColumnGridBoxes"

const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
      <LayoutA

          title={"Ästhetik und Schönheitsoperationen in Wilhelmshaven | VeoMed"}
          description={"Ästhetik mit High-Tech Und Verantwortung – Wahre Schönheit kommt aus Ihnen. Schönheits-OP's, Behandlungen und Korrekturen.  "}
      >
      <ScrollHeader />
      <LogoHeadlineTeaser
        py={24}
        headline="VeoAesthetics"
        subheadline="Wahre Schönheit kommt aus Ihnen"
      />

      <Container variant="layoutContainer" mt={12}>
        <ThreeColumnGridBoxes />
      </Container>

      <LogoHeadlineTeaser
        my={12}
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />
      <QuadTeaser />
      <Container mt={8} variant="layoutContainer">
        <BannerVeoJadeklinik />
      </Container>
    </LayoutA>
  )
}

export default Blank
